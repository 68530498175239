export const REQUEST_STATUS = {
  loading: 'loading',
  success: 'success',
  error: 'error',
  idle: 'idle',
};

export default {
  computed: {
    success() {
      return this.requestStatus && this.requestStatus === REQUEST_STATUS.success;
    },
    loading() {
      return this.requestStatus && this.requestStatus === REQUEST_STATUS.loading;
    },
    error() {
      return this.requestStatus && this.requestStatus === REQUEST_STATUS.error;
    },
    idle() {
      return this.requestStatus && this.requestStatus === REQUEST_STATUS.idle;
    }
  }
};
