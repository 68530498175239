export default {
  '404': {
    title: 'Page not found!',
    body: 'This page does not exists.',
    return: 'Go Back'
  },
  '403': {
    title: 'Access denied!',
    body: 'You do not have the right permissions to access this page.',
    return: 'Go Back'
  },
  '500': {
    title: 'Oops... An error was raised!',
    body: 'We may be experiencing some tecnical dificulties. Please try again later.',
    return: 'Go Back'
  }
};
