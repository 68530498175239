export default {
  master: 'Master',
  ticketCreate: 'Criar tickets',
  ticketEditOwned: 'Editar tickets (próprios)',
  ticketEditAll: 'Editar tickets (todos)',
  ticketStatusEdit: 'Alterar status',
  ticketAssigneeEdit: 'Delegar',
  ticketAnswer: 'Responder como nota',
  ticketAnswerToResponding: 'Enviar para o módulo Conversas',
  ticketViewOwned: 'Visualizar tickets (próprios e atribuídos)',
  ticketViewAll: 'Visualizar todos os tickets',
  ticketDeleteOwned: 'Excluir um ticket (próprio)',
  ticketDeleteAll: 'Excluir tickets',
  ticketInvite: 'Convidar usuário',
  permissionManagement: 'Gerenciar perfil',
  cannotEditPermission: 'Você não pode editar um perfil interno'
};
