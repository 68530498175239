import store from '@/store/store';
import { authentication } from '@stilingue/st-requests';
import STORAGE_KEYS from '@/utils/constants/session_storage';

export function beforeHomeGuard(to, from, next) {
  const auth = authentication();
  const SSID = auth.getSessionId();

  if (!SSID) {
    return next({ name: 'login' });
  }

  return store.dispatch('getInitialData')
    .then(([, companies]) => {
      if (companies && companies.length > 1) {
        return next();
      }
      const path = to.params.np_red || '/notificacoes';
      return next({ path });
    })
    .catch(() => {
      next({ name: 'error', params: { errorStatus: 403 } });
    });
}

export function beforeRouteGuard(to, from, next) {
  const auth = authentication();
  const SSID = auth.getSessionId();

  if (!SSID) {
    sessionStorage.setItem(STORAGE_KEYS.redirectAfterLogin, to.path);
    return next({ name: 'login', params: { np_red: to.path } });
  }

  const isAuthenticated = store.getters.isAuthenticated;
  // Follow on the navigation
  if (isAuthenticated) {
    return next();
  }

  next({ name: 'home', params: { np_red: to.path } });
}

export function loginGuard(to, from, next) {
  const auth = authentication();
  const SSID = auth.getSessionId();

  if (SSID) {
    return next({ name: 'home' });
  }
  return next();
}
