export default {
  user: null,
  role: {
    isSuperAdmin: true,
    isAccountAdmin: true,
    master: true,
    permissions: { canViewPermissions: true }
  },
  requestStatus: 'idle',
  repliersById: {}
};
