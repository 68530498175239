import createApi from './auth';
import Service from './Service';

import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.RoleServiceVersionProd.getValue()
  : StFeatureFlagFlags.RoleServiceVersionStaging.getValue();

const afterParser = (response) => response.data;

class RoleService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  list(params) {
    return this.api.get({ params, url: '' }).then(this.afterParser);
  }

  create(data) {
    return this.api.put(data, { url: '' }).then(this.afterParser);
  }

  save(roleId, data) {
    return this.api.post({ ...data, url: `/${roleId}` }).then(this.afterParser);
  }
}

const api = createApi(`upms/${version}/role`);

export default new RoleService(api);
