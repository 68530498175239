import Vue from 'vue';
import Vuex from 'vuex';
import stFeatureFlag from '@/store/modules/st_feature_flag';
import role from '@/store/modules/role';
import user from '@/store/modules/user';
import company from '@/store/modules/company';
import permissions from '@/store/modules/permissions';
import misc from '@/store/modules/misc';

import { state, mutations, actions, getters } from './main_store';

// Add logrocket and LogRocket vuex plugin
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    stFeatureFlag,
    role,
    permissions,
    user,
    company,
    misc
  },
  state,
  mutations,
  actions,
  getters,
  plugins: [logrocketPlugin]
});

export default store;
