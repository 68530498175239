export default {
  name: 'Configurações',
  sidebar: {
    roles: 'Perfis',
    users: 'Usuários',
    notifications: 'Notificações',
    permissions: 'Permissões',
  },
  company: {
    pageTitle: 'Selecione uma organização:',
    placeholder: 'Selecione uma organização',
  },
  header: {
    greeting: 'Olá',
    menus: {
      home: 'Início',
      account: 'Gerenciar conta ativa',
      login: 'Meu Login',
      logout: 'Sair',
    },
  },
  navigationDrawer: {
    back: 'voltar para',
    modulesLabel: 'Módulos',
    modules: {
      tickets: 'Tickets',
      permissions: 'Configurações',
      warroom: 'War-Room',
      sac: 'Conversas',
      performance: 'Métricas Proprietárias',
    },
  },
};
