import { set } from '@/plugins/lodash';
import Vue from 'vue';

function hasPropertyAndWith(payload) {
  return payload.hasOwnProperty('property') &&
    payload.hasOwnProperty('with');
}

function hasPropertyAndKeyAndWith(payload) {
  return payload.hasOwnProperty('property') &&
    payload.hasOwnProperty('key') &&
    payload.hasOwnProperty('with');
}

function hasPropertyAndIndexAndWith(payload) {
  return payload.hasOwnProperty('property') &&
    payload.hasOwnProperty('index') &&
    payload.hasOwnProperty('with');
}

function hasPropertyAndKeyAndWithAndIndex(payload) {
  return payload.hasOwnProperty('property') &&
    payload.hasOwnProperty('index') &&
    payload.hasOwnProperty('key') &&
    payload.hasOwnProperty('with');
}

/**
 * Override the property passed in the object
 * @param state: current state of the store
 * @param payload: array of object with "Property" and "With"
 * @returns {boolean|Error}:  true if updated the object, false otherwise
 */
export function mutate(state, payload) {
  if (!hasPropertyAndWith(payload)) {
    throw new Error(`${payload} Must have attributes property and with`);
  }

  set(state, payload.property, payload.with);
  return true;
}

/**
 * Override all the properties passed in the array of objects
 * @param state: current state of the store
 * @param payloads: array of object with "Property" and "With"
 * @returns {boolean|Error}:  true if updated the object, false otherwise
 */
export function mutateMultiple(state, payloads) {
  if (!Array.isArray(payloads)) {
    throw new Error(`${payloads} Must be an array`);
  }

  payloads.forEach((payload) => {
    if (!hasPropertyAndWith(payload)) {
      throw new Error(`${payload} Must have attributes property and with`);
    }
  });

  payloads.forEach((payload) => {
    set(state, payload.property, payload.with);
    return true;
  });
}

/**
 * Add or update a object in the store
 * @param state: current state of the store
 * @param payload: object with "Property", "Key" and "With"
 * @returns {boolean|Error}:  true if updated the object, false otherwise
 */
export function mutateObjectKey(state, payload) {
  if (typeof state[payload.property] !== 'object') {
    throw new Error(`${state[payload.property]} Must be an object`);
  }

  if (!hasPropertyAndKeyAndWith(payload)) {
    throw new Error(`${payload} Must have attributes property, key and with`);
  }

  set(state, `${payload.property}.${payload.key}`, payload.with);
  return true;
}

/**
 * Add or update a index of an array in the store
 * @param state: current state of the store
 * @param payload: object with "Property", "Index" and "With"
 * @returns {boolean|Error}: true if updated the object, false otherwise
 */
export function mutateArrayIndex(state, payload) {
  if (!Array.isArray(state[payload.property])) {
    throw new Error(`${state}[${payload.property}] Must be an array`);
  }

  if (!hasPropertyAndIndexAndWith(payload)) {
    throw new Error(`${payload} Must have attributes property, index and with`);
  }

  // Have to use vue.set here, otherwise reactivity is not triggered
  Vue.set(state[payload.property], payload.index, payload.with);
  return true;
}

/**
 * Add or update a key of an object within an array in the store
 * @param state: current state of the store
 * @param payload: object with "Index", "Key", "With", "Property
 * @returns {boolean|Error}:  true if updated the object, false otherwise
 */
export function mutateObjectKeyInArray(state, payload) {
  if (!hasPropertyAndKeyAndWithAndIndex(payload)) {
    throw new Error(`${payload} Must have attributes, index, key, property and with`);
  }

  if (!Array.isArray(state[payload.property])) {
    throw new Error(`${state[payload.property]} Must be an array`);
  }

  if (typeof state[payload.property][payload.index] !== 'object') {
    throw new Error(`${state[payload.property]}[${payload.index}] Must be an object`);
  }

  Vue.set(state[payload.property][payload.index], payload.key, payload.with);
  return true;
}
