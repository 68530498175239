import _camelCase from 'lodash/camelCase';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';

/**
 * This is a wrapper for lodash
 * This is used to avoid importing lodash within components directly
 */
export function camelCase(str) {
  return _camelCase(str);
}

export function set(obj, path, value) {
  return _set(obj, path, value);
}

export function cloneDeep(objects) {
  return _cloneDeep(objects);
}

export function has(object, path) {
  return _has(object, path);
}
