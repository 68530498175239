import createApi from './auth';
import Service from './Service';
import { camelCase } from '@/plugins/lodash';
import i18n from '@/plugins/i18n';
import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.PermissionServiceVersionProd.getValue()
  : StFeatureFlagFlags.PermissionServiceVersionStaging.getValue();

const afterParser = (response) => {
  const data = response.data;
  return data.map(permission => {
    return {
      id: permission.id,
      text: i18n.t(`Permissions.${camelCase(permission.id)}`),
      isActive: false,
      isSystem: permission.system
    };
  });
};

class PermissionService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  list(params = { product: 'ticket' }) {
    return this.api.get({ params, url: '' })
      .then(this.afterParser);
  }
}

const api = createApi(`upms/${version}/permission`);

export default new PermissionService(api);
