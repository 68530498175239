import createApi from './auth';
import Service from './Service';

import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.UserRoleServiceVersionProd.getValue()
  : StFeatureFlagFlags.UserRoleServiceVersionStaging.getValue();

const afterParser = (response) => response.data;

class UserCompanyRoleService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  updateUserRole(data, userId) {
    return this.api.post({ ...data, url: `/${userId}` });
  }

  deleteRoleFromUser(params, userId) {
    return this.api.delete({ params, url: `/${userId}` });
  }
}

const api = createApi(`upms/${version}/user-company-role`);
export default new UserCompanyRoleService(api);
