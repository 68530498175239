export default {
  pageTitle: 'Gerenciar Notificações',
  submit: 'Concluir',
  notifications: 'Notificações',
  emailTip: 'Receber notificações por e-mail',
  saveSuccessFeedback: 'Preferências alteradas com sucesso',
  saveErrorFeedback: 'Erro ao salvar',
  roles: {
    statusEnabled: 'Notificar sobre alteração de status de tickets',
    assigneeEnabled: 'Notificar sobre alteração de responsável em tickets',
    noteCreationEnabled: 'Notificar sobre novas notas adicionadas',
    watcherEnabled: 'Notificar quando for adicionado para acompanhar um ticket',
  },
};
