import Vue from 'vue';
import Router from 'vue-router';
import { beforeHomeGuard, beforeRouteGuard, loginGuard } from '@/guards/router.guard';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      beforeEnter: loginGuard,
      component: () => import('./views/Login.vue'),
      props: true
    },
    {
      path: '/perfis',
      beforeEnter: beforeRouteGuard,
      component: () => import('./views/PageLayout.vue'),
      props: true,
      children: [
        {
          path: 'novo',
          name: 'new-role',
          props: true,
          component: () => import('./views/NewRole.vue')
        },
        {
          path: 'editar/:id',
          name: 'edit-role',
          props: true,
          component: () => import('./views/EditRole.vue')
        },
        {
          path: '',
          name: 'role-list',
          props: true,
          component: () => import('./views/RoleList.vue')
        }
      ]
    },
    {
      path: '/notificacoes',
      beforeEnter: beforeRouteGuard,
      component: () => import('./views/PageLayout.vue'),
      props: true,
      children: [
        {
          path: '',
          name: 'notification-settings',
          props: true,
          component: () => import('./views/NotificationsSettings.vue')
        },
      ]
    },
    {
      path: '/usuarios',
      beforeEnter: beforeRouteGuard,
      component: () => import('./views/PageLayout.vue'),
      children: [
        {
          path: '',
          name: 'user-list',
          props: true,
          component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
        }
      ]
    },
    {
      path: '/error',
      alias: ['403', '500'],
      name: 'error',
      component: () => import('./views/ErrorPageWrapper.vue'),
      props: true
    },
    {
      path: '/',
      name: 'home',
      beforeEnter: beforeHomeGuard,
      component: () => import('./views/CompanySelection.vue'),
      props: true
    },
    {
      path: '*',
      redirect: '/error'
    }
  ]
});

export default router;
