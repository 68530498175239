import createApi from './auth';
import Service from './Service';
import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.AuthServiceVersionProd.getValue()
  : StFeatureFlagFlags.AuthServiceVersionStaging.getValue();

class AuthService extends Service {
  constructor(api) {
    super(api, { });
  }

  login(username, password) {
    const params = { username, password };
    return this.api.post({ ...params, url: 'login' });
  }

  logout(SSID) {
    const params = { 'Stilingue-Session-Id': SSID };
    return this.api.post({ ...params, url: 'logout' });
  }
}

const api = createApi(`auth/${version}/session/`, {
  authNotFound: () => true,
  error401Handler: (error) => {
    return Promise.reject(error);
  },
  error404Handler: (error) => {
    return Promise.reject(error.response);
  }
});
export default new AuthService(api);
