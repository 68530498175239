import createApi from './auth';
import Service from './Service';

import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const forceVersion = '';
const version = forceVersion ? forceVersion : isProdVersion()
  ? StFeatureFlagFlags.UserServiceVersionProd.getValue()
  : StFeatureFlagFlags.UserServiceVersionStaging.getValue();

const afterParser = response => response.data;

class UserService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  getUserById(id, params = {}) {
    return this.api.get({ params, url: `${id}/identification` }).then(this.afterParser);
  }

  listUsersWithRoles(companyId) {
    return this.api.get({ url: `company/${companyId}/all` }).then(this.afterParser);
  }

  getUsersByRoleId(roleId, params = {}) {
    return this.api.get({ params, url: `role/${roleId}` }).then(this.afterParser);
  }
}

const api = createApi(`upms/${version}/user/`);
export default new UserService(api);
