export default function URLController(urlConnector) {
  return {
    getParamsFromURL() {
      return urlConnector.getQueryParams();
    },
    getParamsByName(paramName) {
      return urlConnector.getQueryParamByName(paramName);
    },
    setParamInUrl(paramName, paramValue) {
      urlConnector.setQuery({ [paramName]: paramValue });
      return this;
    },
    removeParamInUrl(paramName) {
      return urlConnector.removeQueryParam(paramName);
    }
  };
}
