export default function NativeUrlConnector(window) {
  const { URLSearchParams, location, history } = window;

  function updateURL(queryParams) {
    history.replaceState({}, '', `${location.pathname}?${queryParams}`);
  }

  return {
    getQueryParams() {
      return new URLSearchParams(location.search);
    },
    getQueryParamByName(paramName) {
      return this.getQueryParams().get(paramName) || '';
    },
    setQuery(query) {
      const params = this.getQueryParams();
      Object.entries(query).forEach(([key, value]) => {
        params.append(key, value);
      });
      updateURL(params);
    },
    removeQueryParam(paramName) {
      const params = this.getQueryParams();
      params.delete(paramName);
      updateURL(params);
    }
  };
}
