export default {
  title: 'Usuários',
  searchLabel: 'Buscar usuário ou perfil',
  column: {
    name: 'Nome',
    email: 'E-mail',
    role: 'Perfil'
  },
  invite: 'Convidar',
  remove: 'Remover',
  cancel: 'Cancelar',
  alter: 'Alterar',
  yes: 'Sim',
  confirmMessages: {
    removeUserFromRole: 'Deseja remover o perfil {userRole} do usuário {userName}?',
    alterUserRole: 'Deseja alterar o perfil do usuário {userName} de {changingRoleFromName} para {roleName}?',
    inviteUser: 'Deseja convidar {userName}?'
  },
  error: {
    toAlterRole: 'Ocorreu uma falha ao alterar o perfil do usuário. Por favor, tente novamente mais tarde.',
    toInviteToRole: 'Ocorreu uma falha ao convidar o usuário. Por favor, tente novamente mais tarde.',
    toDeleteRole: 'Ocorreu uma falha ao remover o perfil do usuário. Por favor, tente novamente mais tarde.'
  },
  pagination: {
    label: 'Mostrando {rangeStart}-{rangeEnd} de {total}'
  }
};
