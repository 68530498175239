import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import CompanyService from '@/services/CompanyService';

export function setActiveCompany({ state, commit }, company) {
  let companyObj = company;
  if (typeof company === 'number') {
    companyObj = state.companies.filter(comp => comp.id === company)[0];
  }

  commit(MUTATE, {
    property: 'activeCompany',
    with: companyObj
  });
}

export function getCompanies({ state, commit, dispatch }) {
  if (state.companies && state.companies.length) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'success'
    });
    return Promise.resolve(state.companies);
  }

  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading'
  });

  return CompanyService.find()
    .then(data => {
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success'
        }, {
          property: 'companies',
          with: data
        }
      ]);
      if (data.length === 1) {
        dispatch('setActiveCompany', data[0]);
      }

      return Promise.resolve(data);
    }).catch(() => {
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error'
      });

      return Promise.reject();
    });
}
