export default {
  nameRules: {
    required: 'Name is obrigatório',
    notEmpty: 'Name should no be empty',
    maxLength: 'Name must have max {max} characters'
  },
  permissionsRules: {
    minLength: 'Select at least {min} permission(s)'
  }
};
