var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.appClasses },
    [
      _c(
        "v-app",
        [
          !_vm.loading
            ? _c(
                "v-row",
                { staticClass: "no-gutters" },
                [
                  _vm.success || _vm.error || _vm.idle
                    ? _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [_c("keep-alive", [_c("router-view")], 1)],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("StLoader", { attrs: { loading: _vm.loading } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }