import createApi from './auth';
import Service from './Service';

import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.UserLegacyServiceVersionProd.getValue()
  : StFeatureFlagFlags.UserLegacyServiceVersionStaging.getValue();

const afterParser = response => response.data;

class UserLegacyService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  me(params = {}) {
    return this.api.get({ params, url: 'me' }).then(this.afterParser);
  }
}

const api = createApi(`upms-legacy/${version}/user/`);
export default new UserLegacyService(api);
