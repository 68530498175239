import { authentication } from '@stilingue/st-requests';

import NativeUrlConnector from '@/utils/filter/native_url_connector';
import URLController from '@/utils/filter/url_controller';

import router from '@/router';

const authStore = authentication({ tokenName: 'stilingue-session-id' });

function findSessionIdOnCookie() {
  return authStore.getSessionId();
}

function findSessionIdOnURL() {
  const nativeUrlConnector = NativeUrlConnector(window);
  const urlController = URLController(nativeUrlConnector);

  return urlController.getParamsByName('ssid');
}

function removeSessionIdOnURL() {
  const nativeUrlConnector = NativeUrlConnector(window);
  const urlController = URLController(nativeUrlConnector);

  return urlController.removeParamInUrl('ssid');
}

function findAndHandleSessionId() {
  const sessionIdOnCookie = findSessionIdOnCookie();
  if (sessionIdOnCookie) {
    removeSessionIdOnURL();
    return sessionIdOnCookie;
  }

  const sessionIdOnURL = findSessionIdOnURL();
  if (sessionIdOnURL) {
    authStore.setSessionId(sessionIdOnURL, { expires: 365 });
    removeSessionIdOnURL();
    return sessionIdOnURL;
  }

  return null;
}

function getDomain() {
  const { host } = window.location;
  const productionDomain = '.stilingue.com.br';
  return host.match(productionDomain) ? productionDomain : '';
}

export function checkForAuthentication() {
  const sessionId = findAndHandleSessionId();

  if (!sessionId) {
    logoutAndSendUserToLoginPage();
    return false;
  }

  return sessionId;
}

export function setCookie(cookieName, token) {
  const auth = authentication({ tokenName: cookieName });
  return auth.setSessionId(token, { domain: getDomain() });
}

/**
 * Make login for New authentication and old one.
 * PS: usr_id uses "_" because the old way to communicate with backend was with snake_case.
 * @param SSID Stilingue-session-id , token of the new authentication
 * @param usr_id cookie used in old authentication system
 */
export function login({ SSID = '', usr_id = '' } = {}) {
  SSID && setCookie('stilingue-session-id', SSID);
  usr_id && setCookie('usr_id', usr_id);
}

export function removeCookie(cookieName) {
  const auth = authentication({ tokenName: cookieName });
  return auth.removeSessionId({ domain: getDomain() });
}

export function logout() {
  removeCookie('stilingue-session-id');
  removeCookie('usr_id');
}

export function logoutAndSendUserToLoginPage() {
  logout();
  router.push({ name: 'login', params: { skipRequests: true } }).catch(/*istanbul ignore next*/() => {});
}
