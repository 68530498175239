import actions from '@/store/modules/st_feature_flag/actions';
import mutations from '@/store/modules/st_feature_flag/mutations';
import getters from '@/store/modules/st_feature_flag/getters';
import state from '@/store/modules/st_feature_flag/state';

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters
};
