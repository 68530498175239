import { MUTATE } from '@/store/modules/mutation_types';
import { mutate } from '@/store/modules/mutations';
import {
  TOGGLE_DRAWER
} from './mutation_types';

export default {
  [MUTATE]: mutate,
  [TOGGLE_DRAWER](state) {
    state.drawer = !state.drawer;
  }
};
