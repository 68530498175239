import { MUTATE, MUTATE_ARRAY_INDEX, MUTATE_MULTIPLE } from '../mutation_types';
import UserCompanyRoleService from '@/services/UserCompanyRoleService';
import PermissionService from '@/services/PermissionService';
import UserService from '@/services/UserService';
import { cloneDeep, has } from '@/plugins/lodash';

export const PERMISSIONS = 'permissions';
export const REQUEST_STATUS = 'requestStatus';
export const LOADING = 'loading';
export const SUCCESS = 'success';
export const ERROR = 'error';

export default {
  getPermissions({ commit, state }, product) {
    commit(MUTATE, { property: REQUEST_STATUS, with: LOADING });
    if (state.permissions && state.permissions.length) {
      commit(MUTATE, {
        property: REQUEST_STATUS,
        with: SUCCESS
      });
      return Promise.resolve(state.permissions);
    }

    return PermissionService
      .list(product)
      .then(data => {
        commit(MUTATE_MULTIPLE, [{
          property: PERMISSIONS,
          with: data
        }, {
          property: REQUEST_STATUS,
          with: SUCCESS
        }]);
      })
      .catch((e) => {
        commit(MUTATE, {
          property: REQUEST_STATUS,
          with: ERROR
        });
        throw e;
      });
  },
  fetchUsersWithRoles({ state, rootState, commit }) {
    if (Array.isArray(state.usersWithRoles)) {
      return Promise.resolve();
    }
    commit('MUTATE', { property: REQUEST_STATUS, with: LOADING });
    UserService.listUsersWithRoles(rootState.company.activeCompany.id).then((users) => {
      for (const user of users) {
        if (typeof user.role === 'undefined' || user.role === null || !user.role.hasOwnProperty('name')) {
          user.role = { name: '' };
        }
      }
      commit(MUTATE, { property: 'usersWithRoles', with: users });
      commit(MUTATE, { property: REQUEST_STATUS, with: SUCCESS });
    }).catch(() => {
      commit(MUTATE, { property: REQUEST_STATUS, with: ERROR });
      return Promise.reject();
    }).finally(() => {
      return Promise.resolve();
    });
  },
  removeUserFromRole({ rootState, dispatch }, { userId, changingFromId }) {
    const companyId = rootState.company.activeCompany.id;
    const params = { companyId };
    return UserCompanyRoleService
      .deleteRoleFromUser(params, userId)
      .then(response => {
        const data = response.data;
        if (!data || !data.value) {
          return Promise.reject();
        }

        const newRoleName = '';
        const newRoleId = 0;

        dispatch('updateUserInUsersWithRoles', { userId, newRoleName, newRoleId });
        dispatch('removeUserFromInRoleModule', { userId, changingFromId });
      })
      .catch((e) => {
        console.error(e);
        return Promise.reject();
      });
  },
  updateUserRole({ rootState, dispatch }, { user, roleId, changingFromId }) {
    const companyId = rootState.company.activeCompany.id;
    const userId = user.id;
    const params = { companyId, roleId };
    return UserCompanyRoleService
      .updateUserRole(params, userId)
      .then((response) => {
        const data = response.data;
        if (!data || !data.hasOwnProperty('roleId')) {
          return Promise.reject();
        }
        const alteredRoleTo = rootState.role.roles.filter(role => role.id === data.roleId);
        const roleName = alteredRoleTo[0].name;

        if (alteredRoleTo[0].users) {
          alteredRoleTo[0].users.push(user);
        }

        const newRoleName = roleName;
        const newRoleId = data.roleId;
        dispatch('updateUserInUsersWithRoles', { userId, newRoleName, newRoleId });

        // goes on only if user has already a role
        if (!changingFromId || changingFromId <= 0) {
          return;
        }

        dispatch('removeUserFromInRoleModule', { userId, changingFromId });
      })
      .catch((e) => {
        console.error(e);
        return Promise.reject();
      });
  },
  removeUserFromInRoleModule({ rootState, commit }, { userId, changingFromId }) {
    const roleIndex = rootState.role.roles.findIndex(role => role.id === changingFromId);
    const role = cloneDeep(rootState.role.roles[roleIndex]);

    if (!role || !has(role, 'users')) {
      return;
    }

    role.users = role.users.filter(roleUser => roleUser.id !== userId);

    // remove altered user from his old role
    commit(`role/${MUTATE_ARRAY_INDEX}`, {
      index: roleIndex,
      property: 'roles',
      with: role
    }, {
      root: true
    });
  },
  updateUserInUsersWithRoles({ state, commit }, { userId, newRoleName, newRoleId }) {
    const index = state.usersWithRoles.findIndex(user => user.id === userId);
    const userData = cloneDeep(state.usersWithRoles[index]);

    userData.role = {
      ...userData.role,
      id: newRoleId,
      name: newRoleName
    };

    commit(MUTATE_ARRAY_INDEX, {
      index,
      with: userData,
      property: 'usersWithRoles'
    });
  }
};
