import { MUTATE, MUTATE_MULTIPLE, MUTATE_ARRAY_INDEX, MUTATE_OBJECT_KEY_IN_ARRAY } from '@/store/modules/mutation_types';

import RoleService from '@/services/RoleService';
import UserService from '@/services/UserService';

const getCompanyId = (commit, state) => {
  const companyId = state.company && state.company.activeCompany
    ? state.company.activeCompany.id
    : null;

  if (!companyId) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'error'
    });

    return Promise.reject();
  }

  return Promise.resolve(companyId);
};

export default {
  async getRoles({ commit, state, rootState }) {
    if (Array.isArray(state.roles)) {
      return state.roles;
    }

    commit(MUTATE, {
      property: 'requestStatus',
      with: 'loading'
    });

    let companyId;

    try {
      companyId = await getCompanyId(commit, rootState);
    } catch (error) {
      return Promise.reject();
    }

    return RoleService
      .list({ companyId })
      .then(data => {
        commit(MUTATE_MULTIPLE, [{
          property: 'roles',
          with: data
        }, {
          property: 'requestStatus',
          with: 'success'
        }]);

        return data;
      })
      .catch((e) => {
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'error'
        });

        throw e;
      });
  },

  async createRole({ commit, rootState, state }, role) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'loading'
    });

    let companyId;

    try {
      companyId = await getCompanyId(commit, rootState);
    } catch (error) {
      return Promise.reject();
    }

    return RoleService
      .create({ companyId, ...role })
      .then(data => {
        commit(MUTATE_MULTIPLE, [{
          property: 'roles',
          with: [...state.roles, data]
        }, {
          property: 'requestStatus',
          with: 'success'
        }]);
      })
      .catch((e) => {
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'error'
        });

        throw e;
      });
  },

  async saveRole({ commit, rootState, state }, { roleId, role }) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'loading'
    });

    let companyId;

    try {
      companyId = await getCompanyId(commit, rootState);
    } catch (error) {
      return Promise.reject();
    }

    return RoleService
      .save(roleId, { companyId, ...role })
      .then(data => {
        const index = state.roles.findIndex(r => {
          return r.id === roleId;
        });

        data.users = state.roles[index].users;

        commit(MUTATE_ARRAY_INDEX, {
          index,
          with: data,
          property: 'roles'
        });

        commit(MUTATE, {
          property: 'requestStatus',
          with: 'success'
        });
      })
      .catch((e) => {
        console.error(e);
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'error'
        });

        throw e;
      });
  },

  async getRoleUsers({ commit, rootState, getters }, roleId) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'loading'
    });

    let companyId;

    try {
      companyId = await getCompanyId(commit, rootState);
    } catch (error) {
      return Promise.reject();
    }

    const role = getters.getRoleById(roleId);
    const index = getters.getRoleIndexById(roleId);

    if (role && role.users) {
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'success'
      });

      return role.users;
    }

    return UserService
      .getUsersByRoleId(roleId, { companyId })
      .then(data => {
        commit(MUTATE_OBJECT_KEY_IN_ARRAY, {
          index,
          property: 'roles',
          key: 'users',
          with: data
        });

        commit(MUTATE, {
          property: 'requestStatus',
          with: 'success'
        });

        return data;
      })
      .catch((e) => {
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'error'
        });

        throw e;
      });
  }
};
