import Role from './pt/Role.js';
import App from './pt/App.js';
import Users from './pt/Users';
import ErrorStatus from './pt/ErrorStatus.js';
import Permissions from './pt/Permissions.js';
import Login from './pt/Login.js';
import FormRules from './pt/FormRules.js';
import NotificationsSettings from './pt/NotificationsSettings.js';

export default {
  App,
  Role,
  ErrorStatus,
  Permissions,
  Users,
  Login,
  FormRules,
  NotificationsSettings,
};
