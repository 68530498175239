import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/noty/noty';
import App from './App';
import router from './router';
import store from './store/store';
import i18n from './plugins/i18n';
import './plugins/flag_icon';

import '@stilingue/st-styles/main.scss';
import '@stilingue/st-styles/text.scss';
import { checkForAuthentication } from '@/services/auth_manager';

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');

checkForAuthentication();

/**
 * Import third party libs
 */
import './plugins/v-tooltip/v-tooltip';

import './plugins/sentry';
import '@/plugins/hotjar';
