import { MUTATE } from '@/store/modules/mutation_types';
import { mutate } from '@/store/modules/mutations';

export const state = {
  requestStatus: 'idle'
};

export const mutations = {
  [MUTATE]: mutate
};

export const actions = {
  getInitialData({ dispatch, commit, rootState }) {
    const user = rootState.user.user;
    const companies = rootState.user.companies;

    commit(MUTATE, {
      property: 'requestStatus',
      with: 'loading'
    });

    if (user && companies && companies.length) {
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'success'
      });
      return Promise.resolve([user, companies]);
    }

    const userPromise = dispatch('user/getUserMe');
    const companiesPromise = dispatch('company/getCompanies');

    return Promise.all([userPromise, companiesPromise])
      .then(([user, companies]) => {
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'success'
        });

        return Promise.resolve([user, companies]);
      }).catch(err => {
        commit(MUTATE, {
          property: 'requestStatus',
          with: 'error'
        });

        return Promise.reject(err);
      });
  }
};

export const getters = {
  isAuthenticated(state, getters) {
    const user = state.user.user;
    const companies = getters['company/companies'];
    return user && companies && companies.length;
  }
};
