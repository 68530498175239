export default {
  nameRules: {
    required: 'O nome é obrigatório',
    notEmpty: 'O nome não pode conter somente espaços',
    maxLength: 'O nome deve possuir no máximo {max} caracter | O nome deve possuir no máximo {max} caracteres'
  },
  permissionsRules: {
    minLength: 'Selecione pelo menos {min} permissão | Selecione pelo menos {min} permissões'
  },
  emailRules: {
    required: 'O e-mail não pode ser vazio',
    invalid: 'O e-mail não é válido'
  },
  passwordRules: {
    required: 'A senha não pode ser vazia'
  }
};
