import actions from '@/store/modules/permissions/actions';
import mutations from '@/store/modules/permissions/mutations';
import state from '@/store/modules/permissions/state';

export default {
  namespaced: true,
  mutations,
  actions,
  state
};
