import Role from './en/Role.js';
import App from './en/App.js';
import Users from './en/Users';
import ErrorStatus from './en/ErrorStatus.js';
import Permissions from './en/Permissions.js';
import Login from './en/Login.js';
import FormRules from './en/FormRules.js';

export default {
  App,
  Role,
  ErrorStatus,
  Permissions,
  Users,
  Login,
  FormRules
};
