export default {
  '404': {
    title: 'Página não encontrada!',
    body: 'Essa página não existe. Verifique o link de acesso antes de continuar.',
    return: 'Voltar'
  },
  '403': {
    title: 'Acesso não permitido!',
    body: 'Você não tem a permissão necessária para acessar essa página.',
    return: 'Voltar'
  },
  '500': {
    title: 'Ops... Ocorreu um erro!',
    body: 'Estamos passando por algumas dificuldades. Tente novamente em alguns minutos.',
    return: 'Voltar'
  }
};
