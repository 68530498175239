const PRODUCTION_ENV = 'production';
const STAGING_ENV = 'staging';
const DEVELOPMENT_ENV = 'development';

const FORCE_ENV = '';
export const ENV = () => FORCE_ENV || process.env.NODE_ENV;

export function isProdVersion() {
  return ENV() === PRODUCTION_ENV;
}

export function isStagingVersion() {
  return ENV() === STAGING_ENV;
}

export function isNotRunningLocally() {
  return ENV() !== DEVELOPMENT_ENV;
}
