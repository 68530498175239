import Rox from 'rox-browser';

import { isProdVersion } from '@/utils/environment_controller';

export const StFeatureFlagController = Rox;

/**
 * Rollout api key
 */
const RolloutProduction = '5d24ee3641290b646bd01f38';
const RolloutStaging = '5d5be933c3cb8b1634f1f99b';
export const StFeatureFlagKey = isProdVersion() ? RolloutProduction : RolloutStaging;

/**
 * Add User informations here
 */
export const StFeatureFlagUser = { 'key': 'aa0ceb', 'anonymous': true };

/**
 * Flags
 */
export const StFeatureFlagFlags = {
  // Login endpoint controller
  loginUrlProd: new StFeatureFlagController.Configuration('https://warroom.stilingue.com.br/login'),
  loginUrlStaging: new StFeatureFlagController.Configuration('https://tkt-98.warroom-test.com.br/login'),

  gatewayProd: new StFeatureFlagController.Configuration('https://secure-backend-api.stilingue.com.br/'),
  gatewayStaging: new StFeatureFlagController.Configuration('https://secure-backend-api-staging.stilingue.com.br/'),

  // Auth Service
  AuthServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  AuthServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  /**
   * Service Versions Controller
   */
  // Company Service
  CompanyServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  CompanyServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // Permission Service
  PermissionServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  PermissionServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // Role Service
  RoleServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  RoleServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // User Service
  UserServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  UserServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // User Role Service
  UserRoleServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  UserRoleServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // User Legacy Service
  UserLegacyServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  UserLegacyServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // Notifications Settings Service
  NotificationsSettingsServiceVersionProd: new StFeatureFlagController.Configuration('prod'),
  NotificationsSettingsServiceVersionStaging: new StFeatureFlagController.Configuration('staging'),
  // Flags
  'use-zendesk': new StFeatureFlagController.Flag(false),
  'show-zendesk-guide': new StFeatureFlagController.Flag(false),
};

/**
 * Set the custom property in feature flag
 * @param key Searchable key, this key can be used to filter the access to the flag
 * @param value of this property
 */
function setCustomAtribute(key, value) {
  StFeatureFlagController.setCustomStringProperty(key, value);
}
export function insertRolloutMetadata({
  user_email,
  user_name,
  user_id,
  user_group,
  user_group_id
} = {}) {
  // Should have Sentry installed and all user attributes
  const allAttributes = { user_email, user_name, user_id, user_group, user_group_id };
  if (Object.values(allAttributes).every(Boolean)) {
    for (const attr in allAttributes) {
      if (allAttributes.hasOwnProperty(attr)) {
        setCustomAtribute(`${attr}`, `${allAttributes[attr]}`);
      }
    }
    return true;
  }
  // If should install and do not have mandatory info
  console.error('It may be missing some mandatory info, check the following', allAttributes);
}

/**
 * Start Rollout
 */
Rox.register('default', StFeatureFlagFlags);
Rox.setup(StFeatureFlagKey, {
  freeze: 'none'
});

/**
 * Show overrides locally, this is useful to test the featureflag
 */
// if (process.env.NODE_ENV !== 'production') {
//   StFeatureFlagController.showOverrides();
// }
