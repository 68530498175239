export default {
  user: 'user | users',
  roleList: {
    roleListTitle: 'Permissions',
    roleListSubtitle: 'Roles',
    roleListDescription: '',
    addRole: 'Add new role',
    isDefaultRole: 'System\'s default role',
    isCustomRole: 'User\'s defined role'
  },
  createEditRolePage: {
    createTitle: 'New Role',
    editTitle: 'Edit Role',
    roleName: 'Role Name',
    roleNameDescription: '',
    products: 'Products',
    thisRolePermissions: 'Permissions this Role',
    noPermission: 'You do not have access permission',
    errorCreatingRole: 'An error ocurred creating a new Role',
    errorEditingRole: 'An error ocurred while editing the Role'
  },
  cancel: 'Cancel',
  failedToLoadText: 'An error ocurred loading data. Click reload and try again'
};
