import {
  MUTATE,
  MUTATE_MULTIPLE,
  MUTATE_OBJECT_KEY,
  MUTATE_OBJECT_KEY_IN_ARRAY,
  MUTATE_ARRAY_INDEX
} from '@/store/modules/mutation_types';

import {
  mutate,
  mutateMultiple,
  mutateObjectKey,
  mutateArrayIndex,
  mutateObjectKeyInArray
} from '@/store/modules/mutations';

export default {
  [MUTATE]: mutate,
  [MUTATE_MULTIPLE]: mutateMultiple,
  [MUTATE_OBJECT_KEY]: mutateObjectKey,
  [MUTATE_OBJECT_KEY_IN_ARRAY]: mutateObjectKeyInArray,
  [MUTATE_ARRAY_INDEX]: mutateArrayIndex
};
