export default {
  title: 'Users',
  searchLabel: 'Find users or role',
  column: {
    name: 'Name',
    role: 'Role'
  },
  invite: 'Invite',
  remove: 'Remove',
  cancel: 'Cancel',
  alter: 'Alter',
  yes: 'yes',
  confirmMessages: {
    removeUserFromRole: 'Do you want to really remove the user {userName} from the role {userRole}?',
    alterUserRole: 'Do you want to really update the role of user {userName} fom {changingRoleFrom} to {roleName}?',
    inviteUser: 'Do you want to invite {userName}?'
  },
  error: {
    toAlterRole: 'There was an error while updating the user role. Please, try again later.',
    toInviteToRole: 'There was an error while invite the user. Please, try again later.',
    toDeleteRole: 'There was an error while deleting the user role. Please, try again later.'
  },
  pagination: {
    label: 'Showing {rangeStart}-{rangeEnd} of {total}'
  }
};
