export default {
  master: 'Master',
  ticketCreate: 'Create tickets',
  ticketEditOwned: 'Edit tickets (owner)',
  ticketEditAll: 'Edit tickets (all)',
  ticketStatusEdit: 'Alter status',
  ticketAssigneeEdit: 'Edit assignee',
  ticketAnswer: 'Answer ticket',
  ticketAnswerToResponding: 'Answer to responding',
  ticketViewOwned: 'View owned tickets',
  ticketViewAll: 'View all tickets',
  ticketDeleteOwned: 'Delete tickets (owner)',
  ticketDeleteAll: 'Delete all tickets',
  ticketInvite: 'Invite users',
  permissionManagement: 'Manage role'
};

