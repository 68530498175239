import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import { isNotRunningLocally, ENV } from '@/utils/environment_controller';

const shouldInstallSentry = isNotRunningLocally();

// This makes sure sentry will install only once
let isSentryInstalled = false;

(function() {
  if (shouldInstallSentry && !isSentryInstalled) {
    isSentryInstalled = true;
    Sentry.init({
      dsn: 'https://406a14610c1b4e73b585340bd03f3c49@sentry.io/1500774',
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true
        })
      ],
      release: process.env.VUE_APP_SENTRY_RELEASE,
      environment: ENV()
    });
  }
}());

export function insertSentryMetadata({
  username,
  email,
  id,
  user_group
} = {}) {
  // Should have Sentry installed and all user attributes
  if ([isSentryInstalled, username, email, id, user_group].every(Boolean)) {
    return Sentry.setUser({
      username,
      email,
      id,
      user_group
    });
  }
  if (shouldInstallSentry) {
    // If should install and do not have mandatory info
    console.error('It may be missing some mandatory info, check the following', isSentryInstalled, username, email, id, user_group);
  }
}

