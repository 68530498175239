export default {
  email: 'Email',
  password: 'Password',
  enter: 'SignIn',
  emailIsRequired: 'E-mail is required',
  emailIsInvalid: 'Invalid e-mail',
  passwordIsRequired: 'Password is required',
  notFound: 'User or password are invalid',
  Unauthorized: 'User or password are invalid',
  genericErrorMsg: 'An error ocurred. Try again later'
};

