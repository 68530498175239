export default {
  user: 'usuário | usuários',
  roleList: {
    roleListTitle: 'Permissão de Produtos',
    roleListSubtitle: 'Perfis',
    roleListDescription: '',
    addRole: 'Adicionar novo perfil',
    isDefaultRole: 'Padrão do sistema',
    isCustomRole: 'Perfil definido pelo usuário'
  },
  createEditRolePage: {
    createTitle: 'Novo Perfil',
    editTitle: 'Editar Perfil',
    roleName: 'Nome do Perfil',
    roleNameDescription: 'Nome do Perfil é a forma com que serão listados os Níveis de Acesso, com suas repectivas permissões.',
    products: 'Produtos',
    thisRolePermissions: 'Permissões desse Perfil',
    noPermission: 'Você não tem permissão de acesso',
    errorCreatingRole: 'Ocorreu um erro ao criar o perfil. Por favor, tente novamente mais tarde.',
    errorEditingRole: 'Ocorreu um erro ao editar o perfil. Por favor, tente novamente mais tarde.',
    usersInRole: 'Usuários desse Perfil',
    usersInRoleDescription: 'São usuários que terão acesso ao grupo de permissões relacionadas nesse perfil. Você pode alterar e retirar usuários desse perfil.'
  },
  cancel: 'Cancelar',
  failedToLoadText: 'Ocorreu uma falha para carregar os dados. Clique para tentar novamente.',
  roleCard: {
    showMoreIcon: 'Visualizar todos usuários deste perfil.'
  }
};
