export default {
  name: 'Settings',
  sidebar: {
    roles: 'Roles',
    users: 'Users',
    notifications: 'Notifications',
    permissions: 'Permissions',
  },
  company: {
    pageTitle: 'Select an organization:',
    placeholder: 'Select an organization:',
  },
  header: {
    greeting: 'Hello',
    menus: {
      home: 'Home',
      account: 'Account',
      login: 'My Login',
      logout: 'Logout',
    },
  },
  navigationDrawer: {
    back: 'back to',
    modulesLabel: 'Modules',
    modules: {
      tickets: 'Tickets',
      permissions: 'Configurações',
      warroom: 'War-Room',
      sac: 'Conversas',
      performance: 'Métricas Proprietárias',
    },
  }
};

