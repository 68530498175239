import createApi from './auth';
import Service from './Service';
import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.CompanyServiceVersionProd.getValue()
  : StFeatureFlagFlags.CompanyServiceVersionStaging.getValue();

const afterParser = (response) => response.data;

class CompanyService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }
}

const api = createApi(`upms/${version}/user-company-role/me/companies`);
export default new CompanyService(api);
