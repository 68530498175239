import StRequests from '@stilingue/st-requests';
import { logoutAndSendUserToLoginPage } from '@/services/auth_manager';
import router from '@/router';
import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

function removeUrlFromData(request) {
  if (['put', 'post'].includes(request.method)) {
    delete request.data.url;
  }
  return request;
}

const gateway = isProdVersion()
  ? StFeatureFlagFlags.gatewayProd.getValue()
  : StFeatureFlagFlags.gatewayStaging.getValue();

export default function createApi(url, { requester = null, authNotFound = logoutAndSendUserToLoginPage, error401Handler = logoutAndSendUserToLoginPage, error404Handler = logoutAndSendUserToLoginPage } = {}) {
  const api = new StRequests(url, requester);

  api.use({
    baseURL: gateway,
    retry: false,
    timeout: 2 * 60 * 1000, //2 min timeout
    interceptors: {
      request: removeUrlFromData
    },
    errorStatusInterceptors: {
      authNotFound,
      401: error401Handler,
      403: () => {
        router.push({ name: 'error', params: { skipRequests: true, errorStatus: '403' } }).catch(/*istanbul ignore next*/() => {});
      },
      404: error404Handler
    },
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  });

  return api;
}
