<template>
  <div
    :class="appClasses"
  >
    <v-app>
      <v-row
        v-if="!loading"
        class="no-gutters"
      >
        <transition
          v-if="success || error || idle"
          name="fade"
        >
          <keep-alive>
            <router-view />
          </keep-alive>
        </transition>
      </v-row>
      <StLoader :loading="loading" />
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StLoader from '@stilingue/st-loader/StLoader';
import requestStatusMixin from '@/mixins/request_status.mixin';

export default {
  name: 'App',
  components: {
    StLoader
  },
  mixins: [requestStatusMixin],
  computed: {
    ...mapState([
      'requestStatus'
    ]),
    appClasses() {
      return {
        'app--loading': this.loading,
        'app--loaded': !this.loading
      };
    }
  }
};
</script>

<style lang="scss">

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
  @import '@stilingue/st-styles/main.scss';
  @import '~vuejs-noty/dist/vuejs-noty.css';

  * {
    font-family: 'Open Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    background-color: inherit;
    text-align: center;
    color: var(--greyish-brown);

    .no-gutters > div {
      width: 100%;
    }
  }

  .app {
    &--loading {
      background-color: var(--gray-050);
    }

    &--loaded {
      background-color: white;
    }
  }

  html {
    overflow-y: auto !important;
  }

  .disabled__container {
    > * {
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .text-truncate {
    line-height: 1.2 !important;
  }
</style>
