import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import UserLegacyService from '@/services/UserLegacyService';
import AuthService from '@/services/AuthService';
import { login } from '@/services/auth_manager';
import STORAGE_KEYS from '@/utils/constants/session_storage';

export default {
  async getUserMe({ commit, state }) {
    if (state.user) {
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'success'
      });
      return Promise.resolve(state.user);
    }

    try {
      const user = await UserLegacyService.me();
      commit(MUTATE_MULTIPLE, [{
        property: 'user',
        with: user
      }, {
        property: 'requestStatus',
        with: 'success'
      }]);

      return user;
    } catch (e) {
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error'
      });

      return Promise.reject();
    }
  },

  makeLoginRequest({}, { username, password } = {}) {
    if (!(username && password)) {
      return false;
    }

    return AuthService
      .login(username, password)
      .then(data => {
        if (data && 'error' in data) {
          return Promise.reject(data);
        }

        const SSID = data.headers['stilingue-session-id'];
        const usr_id = data.headers['warroom-session-id'];

        if (!(SSID && usr_id)) {
          return Promise.reject({
            response: {
              data: {
                error: 'No Token Received'
              }
            }
          });
        }

        login({ SSID, usr_id });

        const redirectTo = sessionStorage.getItem(STORAGE_KEYS.redirectAfterLogin) || '/';
        sessionStorage.removeItem(STORAGE_KEYS.redirectAfterLogin);

        window.location.href = redirectTo;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};

