const returnFirstArg = (x) => x;

export default class Service {
  constructor(api, options = {}) {
    this.api = api;
    const { afterParser = returnFirstArg } = options;
    this.afterParser = afterParser;
  }

  find(params) {
    return this.api.get(params).then(this.afterParser);
  }

  update(data = {}) {
    return this.api.post(data).then(this.afterParser);
  }

  insert(data = {}) {
    return this.api.put(data).then(this.afterParser);
  }

  remove(params = {}) {
    return this.api.delete({ params }).then(this.afterParser);
  }
}
