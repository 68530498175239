import '@mdi/font/scss/materialdesignicons.scss';
import Vue from 'vue';
import Vuetify, {
  VApp,
  VList,
  VListItem,
  VIcon,
  VNavigationDrawer,
  VListItemAvatar,
  VListItemContent,
  VListItemTitle,
  VListItemIcon,
  VListGroup,
  VSubheader,
  VChip,
  VSelect,
  VAutocomplete,
  VLayout,
  VContent,
  VFlex,
  VBtn,
  VDialog,
  VContainer,
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VAvatar,
  VDataTable,
  VSwitch,
  VTextField,
  VImg,
  VForm,
  VPagination
} from 'vuetify/lib';
import * as directives from 'vuetify/lib/directives';
import pt from 'vuetify/src/locale/pt.ts';

const config = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      dark: {
        primary: '#00aaa7',
        youtube: '#e91d00',
        facebook: '#39579a',
        instagram: '#fbd63f',
        twitter: '#059ff5'
      },
      light: {
        primary: '#00aaa7',
        youtube: '#e91d00',
        facebook: '#39579a',
        instagram: '#fbd63f',
        twitter: '#059ff5'
      }
    }
  },
  lang: {
    locales: {
      pt
    },
    current: 'pt'
  },
  components: {
    VApp,
    VList,
    VListItem,
    VIcon,
    VNavigationDrawer,
    VListItemAvatar,
    VListItemContent,
    VListItemTitle,
    VListItemIcon,
    VListGroup,
    VSubheader,
    VChip,
    VSelect,
    VAutocomplete,
    VLayout,
    VContent,
    VFlex,
    VBtn,
    VDialog,
    VContainer,
    VRow,
    VCol,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VAvatar,
    VDataTable,
    VSwitch,
    VTextField,
    VImg,
    VForm,
    VPagination,
  },
  directives: { ...directives },
};

Vue.use(Vuetify, config);

export default new Vuetify(config);
